<!--  -->
<template>
  <div class="top">
    <div class="head_box">
      <!-- 网络域名设置 -->
      <h4 class="head_title">{{$t('vue_label_systemSettings_social2')}}</h4>
      <div class="action_box">
        <el-input
          size="small"
          suffix-icon="el-icon-search"
          v-model="search"
          @change="searchMethod"
          :placeholder="$t('label.projectManagement.search.for.users')"
          class="actionInput"
        >
        </el-input>
        <!-- 链接账户 -->
        <el-button
          type="primary"
          class="head_bt"
          size="samll"
          @click="
            () => {
              this.dialogVisible = true;
            }
          "
          >{{ $t("c43") }}</el-button
        >
      </div>
    </div>
    <!-- 选择要链接的网络 -->
    <listTable
      ref="listTable"
      :tableAttr="tableAttr"
      :tableData="tableData"
      :tableheight="tableheight"
      :selectvalue="selectvalue"
      :buttonlist="buttonlist"
      :checkDisable="checkDisable"
      :selectionFlg="selectionFlg"
      :loading="loading"
      @relink="relink"
      @swicthCheck="swicthCheck"
      @disconnect="disconnect"
    ></listTable>
    <el-dialog
      :visible.sync="dialogVisible"
      :title="$t('c44')"
      :show-close="true"
      top="15%"
      width="40%"
      :close-on-click-modal="false"
    >
      <div class="createPage">
        <div class="pisc">
          <div class="imgBox" @click="addPost('linkedIn')">
            <svg
              class=""
              aria-hidden="true"
              style="width: 30px; height: 30px; margin-left: 10px"
            >
              <use href="#icon-in"></use>
            </svg>
            <h4 class="ml-10">LinkedIn</h4>
          </div>
          <div class="imgBox" @click="addPost('facebook')">
            <svg
              class=""
              aria-hidden="true"
              style="width: 30px; height: 30px; margin-left: 10px"
            >
              <use href="#icon-facebook_white"></use>
            </svg>
            <h4 class="ml-10">Facebook</h4>
          </div>
          <div class="imgBox" @click="addPost('twitter')">
            <svg
              class=""
              aria-hidden="true"
              style="width: 30px; height: 30px; margin-left: 10px"
            >
              <use href="#icon-twitter_white"></use>
            </svg>
            <h4 class="ml-10">Twitter</h4>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 断联 -->
    <div class="disconnectBox">
    <el-dialog
      :visible.sync="disconnectVisible"
      :popper-append-to-body="false"
      :title="`${$i18n.t('c721')}?`"
      :show-close="true"
      top="15%"
      width="40%"
      popper-class="disconnectBox"
      :close-on-click-modal="false"
    >
      <div class="disconnectText">
        {{$t('c967',[connectName])}}
        <!-- You're about to disconnect {{ connectName }} account.This will remove
        all social media settings and publishing options associated with this
        account -->
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="disconnectVisible = false">{{$t('c720')}}</el-button>
        <el-button type="primary" @click="disconnectAction"
        style="background:#f04b51;border:#f04b51"
          >{{$t('c721')}}</el-button
        >
      </span>
    </el-dialog>
    </div>
  </div>
</template>

<script>
import listTable from "@/views/systemSettings/components/marketSetting/listTable.vue";
import * as marketChannel from "./api";
export default {
  components: { listTable },
  data() {
    return {
      loading: false, //初始化loading
      openFlg: false,
      accountOpen: {},
      connectName: "",
      disconnectVisible: false,
      dialogVisible: false,
      search: "",
      selectionFlg: false,
      //表格数据
      checkDisable: false,
      tableAttr: [
        {
          aa: this.$i18n.t("label.partnerCloud.partnerAccount.account"), //账户
          bb: "socialmedianame",
          cc: false,
          dd: true,
          ee: "D",
          width: "500px",
        },
        {
          aa: this.$i18n.t("c45"), //链接人
          bb: "ownerccname",
          cc: false,
          dd: true,
          ee: "B",
          width: "",
        },
        {
          aa: this.$i18n.t("label.h5.share"), //分享
          bb: "userdefault",
          cc: false,
          dd: true,
          ee: "C",
          width: "",
        },
      ],
      tableData: [],
      selectvalue: {
        selectvalue1: ["clone", "View list performance", "Delete list"],
        selectvalue2: [],
      },
    };
  },
  mounted() {
    window.addEventListener("message", this.receiveMessage, false);
  },
  created() {
    this.initTableDota();
  },
  //方法集合
  methods: {
    searchMethod() {
      if (this.search) {
        let arr = [];
        this.tableData.forEach((event) => {
          if (event.socialmedianame.includes(this.search)) {
            arr.push(event);
          }
        });
        this.tableData = arr;
      } else {
        this.initTableDota();
      }
    },
    // 接收第三方数据授权
    receiveMessage(event) {
      if (this.accountOpen !== {} && this.openFlg) {
        this.accountOpen.close();
        this.openFlg = false;
        let socialDataList = [];
        event.data.accountList.forEach((event) => {
          if (event.issocialauth) {
            socialDataList.push(event);
          }
        });
        marketChannel
          .storeCacheAccessToken({
            orgid: this.$store.state.userInfoObj.orgId,
            socialtype: event.data.socialName,
            socialdata: socialDataList,
          })
          .then((res) => {
            if (res.result) {
              this.$message({
                showClose: true,
                type: "success",
                message: this.$i18n.t("c46"), //授权成功
              });
              this.initTableDota();
            } else {
              this.$message({
                showClose: true,
                type: "error",
                message: res.returnInfo,
              });
            }
          });
      }
    },
    //断开链接
    disconnect(row) {
      this.connectName = row.socialmedianame;
      this.connectId = row.id;
      this.disconnectVisible = true;
    },
    disconnectAction() {
      marketChannel
        .cancelAuthorization({
          orgid: this.$store.state.userInfoObj.orgId,
          userid: this.connectId,
        })
        .then((res) => {
          if (res.result) {
            this.initTableDota();
            this.$refs.listTable.changeTableAttr();
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: res.returnInfo,
            });
          }
        });
      this.disconnectVisible = false;
    },
    swicthCheck(row) {
      marketChannel
        .updateUserDefault({
          orgid: this.$store.state.userInfoObj.orgId,
          userid: row.id,
          userdefault: row.userdefault ? "0" : "1",
        })
        .then((res) => {
          if (res.result) {
            this.$message.success(
              this.$i18n.t("vue_label_notice_modify_success")
            ); //修改成功
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: res.returnInfo,
            });
          }
        });
    },
    initTableDota() {
      this.loading = true;
      marketChannel
        .getScoialUserinfo({
          orgid: this.$store.state.userInfoObj.orgId,
        })
        .then((res) => {
          if (res.result) {
            this.tableData = res.data.list;
            this.loading = false;
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: res.returnInfo,
            });
          }
        });
    },
    relink(row) {
      // row.socialtype
      marketChannel
        .authorizationUrl({
          orgid: this.$store.state.userInfoObj.orgId,
          socialtype: row.socialtype,
        })
        .then((res) => {
          if (res.result) {
            this.accountOpen = window.open(
              res.data,
              this.$i18n.t("label.emailsync.form.signin"), //登录 label.emailsync.form.signin
              "status=no,toolbar=no,menubar=no,location=no,resizable=no,titlebar=no"
            );
            this.openFlg = true;
            this.dialogVisible = false;
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: res.returnInfo,
            });
          }
        });
    },
    addPost(type) {
      marketChannel
        .getAppISConfig({
          orgid: this.$store.state.userInfoObj.orgId,
        })
        .then((res) => {
          if (res.result) {
            res.data.forEach((row) => {
              if (row.type === type && row.isconfig) {
                this.connectUrl(type);
              }
            });
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: this.$i18n.t("c729"),
            });
          }
        });
    },
    
    connectUrl(type) {
      marketChannel
        .authorizationUrl({
          orgid: this.$store.state.userInfoObj.orgId,
          socialtype: type,
        })
        .then((res) => {
          if (res.result) {
            this.accountOpen = window.open(
              res.data,
              this.$i18n.t("label.emailsync.form.signin"), //登录  label.emailsync.form.signin
              "status=no,toolbar=no,menubar=no,location=no,resizable=no,titlebar=no"
            );
            this.openFlg = true;
            this.dialogVisible = false;
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: res.returnInfo,
            });
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .disconnectBox .el-dialog__header {
  padding: 20px 20px 10px;
  background: #f04b51;
}
.disconnectText {
  font-size: 14px;
  height: 150px;
}
.pisc {
  overflow: auto;
  height: 200px;
  .imgBox {
    width: 50%;
    height: 50px;
    border: 1px solid #dedede;
    display: flex;
    cursor: pointer;
    justify-content: left;
    align-items: center;
    margin-bottom: 10px;
    .img {
      margin-left: 10px;
      width: 40px;
      height: 40px;
    }
  }
}
.top {
  padding: 10px;
  .head_box {
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .actionInput {
      width: 330px;
      margin-right: 10px;
    }
    .head_title {
      font-family: SourceHanSansCN-Normal;
      font-size: 12px;
      color: #080707;
    }
    .head_bt {
    }
  }
}
</style>
