import axios from '@/config/httpConfig'
//保存市场云配置信息
export function saveSettingDetail(data) {
    return axios.post('/marketcloud/saveSettingDetail', data)
}
//获取市场云配置信息
export function getSettingDetail(data) {
    return axios.post('/marketcloud/getSettingDetail', data)
}
//保存社交媒体用户和授权token
export function storeCacheAccessToken(data) {
    return axios.post('/social/storeCacheAccessToken', data)
}
//取消Social网站账号授权
export function cancelAuthorization(data) {
    return axios.post('/social/cancelAuthorization', data)
}
//获取领英授权url
export function updateUserDefault(data) {
    return axios.post('/social/updateUserDefault', data)
}
// 查询Social用户信息
export function getScoialUserinfo(data) {
    return axios.post('/social/querySocialUser', data)
}
//获取领英授权url
export function authorizationUrl(data) {
    return axios.post('/social/authorizationUrl', data)
}
//查询集成应用linkedIn，facebook，twitter是否配置
export function getAppISConfig(data) {
    return axios.post('/social/getAppISConfig', data)
}